<template>
  <p></p>
</template>

<script>
///import { mapState } from 'vuex'
import { LOGOUT } from "@/store/actions/auth.type";

export default {
  name: "Logout",
  created() {
    this.$store.dispatch(LOGOUT);
    this.$router.push("/login");
  }
};
</script>
